import {
  AppState,
  Auth0Provider,
  GetTokenSilentlyOptions,
  IdToken,
  useAuth0,
} from "@auth0/auth0-react";
import React, {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import SessionLoading from "../components/SessionLoading/SessionLoading";
import axios from "axios";
import { environment } from "../../environments/environment";
import { ENV_NAMES } from "../../../../../infra-naming-constants";
import { hooks, timer } from "@proximie/components";
import { useDashboardUrl } from "../hooks/useDashboardUrl";

const AUTHENTICATION_LOADING_TIMEOUT_MSECS = 5000;

const { getMillisecondsTillTokenExpiry } = timer;
const { useTimeout } = hooks;

export interface AuthenticatedUserContext {
  token?: string;
}

export const AuthenticatedUserContext =
  createContext<AuthenticatedUserContext | null>(null);

export const useAuthenticatedUser = () => useContext(AuthenticatedUserContext);

const removeAuthQueryParamsAndRedirectToState = (appState?: AppState) => {
  const targetUrl = appState?.target || window.location.pathname;
  window.history.replaceState({}, document.title, targetUrl);
};

interface AuthCheckProps {
  children: ReactElement;
  token?: string;
}

export const AuthCheck: FC<AuthCheckProps> = ({
  children,
  token,
}: AuthCheckProps) => {
  if (!token) {
    return (
      <SessionLoading data-cy="auth-background" data-testid="auth-background">
        Loading
      </SessionLoading>
    );
  }

  return children;
};

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface Auth0User extends Omit<IdToken, "__raw"> {}

interface AuthenticatedUserState {
  auth0User?: Auth0User;
  token?: string;
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const cacheInLocalStorage =
    localStorage.getItem("cacheInLocalStorage")?.toLowerCase() === "true";
  const cacheLocation =
    environment.name === ENV_NAMES.E2E || cacheInLocalStorage
      ? "localstorage"
      : "memory";

  return (
    <Auth0Provider
      domain={environment.oAuth.domain}
      clientId={environment.oAuth.clientId}
      cacheLocation={cacheLocation}
      onRedirectCallback={removeAuthQueryParamsAndRedirectToState}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: environment.oAuth.audience,
        connection: environment.oAuth.connection,
      }}
    >
      <AuthProviderInternal>{children}</AuthProviderInternal>
    </Auth0Provider>
  );
};

export const AuthProviderInternal = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [tokenExpiry, setTokenExpiry] = useState<number | null>(null);
  const [authenticatedUserState, setAuthenticatedUserState] =
    useState<AuthenticatedUserState>();
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const dashboardUrl = useDashboardUrl();

  const tryGetTokenElseLogin = useCallback(
    async (opts?: GetTokenSilentlyOptions) => {
      try {
        return await getAccessTokenSilently(opts);
      } catch (e) {
        console.error("Failed to get token silently", e);
        if (e.error === "login_required") {
          loginWithRedirect({
            appState: {
              target: window.location.pathname + window.location.search,
            },
          });
        }
      }
    },
    [getAccessTokenSilently, loginWithRedirect],
  );

  useTimeout(() => {
    (async () => {
      const newAccessToken = await tryGetTokenElseLogin({ cacheMode: "off" });
      setToken(newAccessToken ?? null);
    })();
  }, tokenExpiry);

  useTimeout(
    () => {
      console.error("auth0 isLoading timer expired");
      if (dashboardUrl) {
        window.location.replace(dashboardUrl);
      }
    },
    token ? 0 : AUTHENTICATION_LOADING_TIMEOUT_MSECS,
  );

  useEffect(() => {
    (async () => {
      if (isLoading) {
        return;
      }

      if (!isAuthenticated) {
        await tryGetTokenElseLogin();
        return;
      }

      if (!user) {
        throw new Error(
          "Auth0 Error: Auth0 Library shows authenticated but no user object populated.",
        );
      }

      const access_token = await getAccessTokenSilently();
      setToken(access_token);
    })();
  }, [
    user,
    tryGetTokenElseLogin,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  ]);

  useEffect(() => {
    (async () => {
      if (!user) return;
      if (!token) {
        const newAccessToken = await getAccessTokenSilently();
        setToken(newAccessToken ?? null);
        return;
      }
      axios.defaults.headers["Authorization"] = `Bearer ${token}`;

      const millisecondsTillExpiry = getMillisecondsTillTokenExpiry(token);

      let delayMs: number | null = null;
      if (millisecondsTillExpiry !== null) {
        delayMs = millisecondsTillExpiry - 60 * 1000;
        if (delayMs === tokenExpiry) {
          // the useTimeout hook will not allow us to start another timer with the same delay as the
          // previous one.  So, if this rare case happens, we increment the delay...
          delayMs++;
        }
      }

      setTokenExpiry(delayMs);
      setAuthenticatedUserState({ token });
    })();
  }, [user, token, getAccessTokenSilently]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).token = token;
  }, [token]);

  const configObject = useMemo(
    () => ({
      token: authenticatedUserState?.token,
    }),
    [authenticatedUserState?.token],
  );

  return (
    <AuthCheck token={authenticatedUserState?.token}>
      <AuthenticatedUserContext.Provider value={configObject}>
        {children}
      </AuthenticatedUserContext.Provider>
    </AuthCheck>
  );
};
