import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Popover,
  Popper,
  SpeedDialAction,
  SpeedDialIcon,
  useTheme,
} from "@mui/material";
import SocketIoClientWrapper from "../../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";
import { Delete } from "@mui/icons-material";
import { Icon } from "@proximie/components";
import { Connection } from "@proximie/media";
import { CursorIcon } from "../icons/CursorIcon/CursorIcon";
import { SpeedDialStyled } from "../SpeedDial/SpeedDial.style";
import { DrawIcon } from "../icons/DrawIcon/DrawIcon";
import { ColorPickerIcon } from "../icons/ColorPickerIcon/ColorPickerIcon";
import { liveApiSockets } from "@proximie/dataregion-api";
import { isDefined } from "@proximie/shared-utils";
import { WidthPicker } from "../WidthPicker/WidthPicker";
import { useTelestration } from "../../contexts/telestration-context";
import { ColorPicker } from "../ColorPicker/ColorPicker";

interface TelestrationControlsProps {
  socket: SocketIoClientWrapper;
  videos: Connection[];
}

const SpeedDialMenuOpen = "SpeedDialMenuOpen";
const SpeedDialActionSelected = "SpeedDialActionSelected";
const ColorPickerOpen = "ColorPickerOpen";

export const TelestrationControls: (
  props: TelestrationControlsProps,
) => JSX.Element = ({ socket, videos }: TelestrationControlsProps) => {
  const { colors } = useTheme();
  const {
    isDrawingMode,
    isEraseLineMode,
    toggleTelestration,
    isCursorMode,
    toggleCursorMode,
    toggleEraser,
    color,
    setColor,
    brushWidth,
    isWidthPickerVisible,
    setIsWidthPickerVisible,
  } = useTelestration();

  const [eventTarget, setEventTarget] = useState<HTMLElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const colorPickerButtonRef = useRef(null);
  const widthPickerButtonRef = useRef(null);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] =
    useState<HTMLElement | null>(null);
  const isColorPickerVisible = Boolean(colorPickerAnchorEl);
  const [widthPickerAnchorEl, setWidthPickerAnchorEl] =
    useState<HTMLElement | null>(null);
  const [cursorIconColor, setCursorIconColor] = useState("");
  const [drawIconColor, setDrawIconColor] = useState("");

  const openColorPicker = (event: React.MouseEvent<HTMLElement>) => {
    setColorPickerAnchorEl(event.currentTarget);
  };

  const closeColorPicker = () => {
    setColorPickerAnchorEl(null);
  };

  const openWidthPicker = (eventTarget: HTMLElement) => {
    setWidthPickerAnchorEl(eventTarget);
  };

  const closeWidthPicker = () => {
    setWidthPickerAnchorEl(null);
  };

  useEffect(() => {
    setIsWidthPickerVisible(Boolean(widthPickerAnchorEl));
  }, [widthPickerAnchorEl, setIsWidthPickerVisible]);

  useEffect(() => {
    setCursorIconColor(colors.WhiteA900);
    setDrawIconColor(colors.WhiteA900);
  }, [colors]);

  useEffect(() => {
    closeColorPicker();
  }, [color]);

  useEffect(() => {
    closeWidthPicker();
  }, [brushWidth]);

  useEffect(() => {
    if (isDrawingMode && isDefined(eventTarget)) {
      openWidthPicker(eventTarget);
      setEventTarget(null);
    }
  }, [eventTarget, isDrawingMode]);

  return (
    <div>
      <SpeedDialStyled
        ariaLabel="Open annotation menu"
        icon={
          menuOpen ? (
            <SpeedDialIcon style={{ color: colors.WhiteA900 }} />
          ) : (
            <DrawIcon color={colors.WhiteA900} />
          )
        }
        FabProps={{
          className: menuOpen ? SpeedDialMenuOpen : undefined,
        }}
        open={menuOpen}
        direction={"down"}
        onClose={(_event, reason) => {
          if (reason === "toggle") {
            setMenuOpen(false);
          }
        }}
        onOpen={(_event, reason) => {
          if (reason === "toggle") {
            setMenuOpen(true);
          }
        }}
      >
        <SpeedDialAction
          data-cy={"toggle-cursor-mode"}
          data-testid={"toggle-cursor-mode"}
          className={isCursorMode ? SpeedDialActionSelected : undefined}
          onMouseEnter={() => setCursorIconColor(colors.Brand200)}
          onMouseLeave={() => setCursorIconColor(colors.WhiteA900)}
          icon={
            <div>
              <CursorIcon color={cursorIconColor} />
              <p>Point</p>
            </div>
          }
          tooltipOpen={false}
          onClick={toggleCursorMode}
        />
        <SpeedDialAction
          data-cy={"toggle-telestration-mode"}
          data-testid={"toggle-telestration-mode"}
          className={isDrawingMode ? SpeedDialActionSelected : undefined}
          onMouseEnter={() => setDrawIconColor(colors.Brand200)}
          onMouseLeave={() => setDrawIconColor(colors.WhiteA900)}
          ref={widthPickerButtonRef}
          icon={
            <div>
              <DrawIcon color={drawIconColor} />
              <p>Draw</p>
            </div>
          }
          tooltipOpen={false}
          onClick={(e) => {
            setEventTarget(e.currentTarget);
            toggleTelestration();
          }}
        />
        <SpeedDialAction
          data-cy={"toggle-open-color-picker"}
          data-testid={"toggle-open-color-picker"}
          className={isColorPickerVisible ? ColorPickerOpen : undefined}
          ref={colorPickerButtonRef}
          icon={
            <div>
              <ColorPickerIcon color={color} />
              <p>Color</p>
            </div>
          }
          tooltipOpen={false}
          onClick={openColorPicker}
        />
        <SpeedDialAction
          className={isEraseLineMode ? SpeedDialActionSelected : undefined}
          data-testid={"toggle-eraser-mode"}
          icon={
            <div>
              <Icon name={"eraser"} />
              <p>Erase</p>
            </div>
          }
          tooltipOpen={false}
          onClick={toggleEraser}
        />
        <SpeedDialAction
          data-cy={"clear-telestrations"}
          data-testid={"clear-telestrations"}
          icon={
            <div>
              <Delete />
              <p>Clear all</p>
            </div>
          }
          tooltipOpen={false}
          onClick={() => {
            videos.forEach((video: Connection): void => {
              socket.sendSync(
                liveApiSockets.MediaSessionEventType.telestration,
                {
                  streamId: video.streamId,
                  command: "clearAll",
                },
              );
            });
          }}
        />
      </SpeedDialStyled>
      <Popover
        open={isColorPickerVisible}
        anchorEl={colorPickerAnchorEl}
        onClose={closeColorPicker}
        PaperProps={{
          style: {
            backgroundColor: colors.Grey900,
            borderRadius: "10px",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: 52,
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box padding={"16px"}>
          <ColorPicker onColorClick={setColor}></ColorPicker>
        </Box>
      </Popover>
      <Popper
        open={isWidthPickerVisible}
        anchorEl={widthPickerAnchorEl}
        placement="right"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 12],
            },
          },
        ]}
      >
        <Box
          padding={"6px"}
          style={{
            backgroundColor: colors.Grey900,
            borderRadius: "10px",
          }}
        >
          <WidthPicker />
        </Box>
      </Popper>
    </div>
  );
};
