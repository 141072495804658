import React, { FC, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorURL from "../../ErrorURL/ErrorURL";
import Error404 from "../../Error404/Error404";
import ErrorSession from "../../ErrorSession/ErrorSession";
import SessionStart from "../../SessionStart/SessionStart";
import SessionInProgress from "../../SessionInProgress/SessionInProgress";
import SessionEnd from "../../SessionEnd/SessionEnd";
import { Page } from "../Page/Page";
import { SessionProvider } from "../../../contexts/session-context/session-context";
import { ServerAdapterProvider } from "../../../contexts/server-adapter-context";
import { UseSocketIoContext } from "../../../contexts/socket-io-context";
import { environment } from "../../../../environments/environment";
import { ENV_NAMES } from "../../../../../../../infra-naming-constants";
import { useAuthenticatedUser } from "../../../contexts/media-client-authenticated-user-context";
import { isValidSessionParams, navigateToDashboard } from "../../../utils";
import TroubleShooter from "../../TroubleShooter/TroubleShooter";
import { SessionLoading } from "../../../components";
import { ExceptionCodes } from "@proximie/common";
import CatchAllErrorPage from "../../CatchAllErrorPage/CatchAllErrorPage";
import { useDashboardUrl } from "../../../hooks/useDashboardUrl";
import { GlobalMonitorProvider } from "../../../contexts/global-monitor-context";
import { CameraLabelProvider } from "../../../contexts/camera-label-context";
import { EmbeddedClientProvider } from "@proximie/components";
import { TelestrationProvider } from "../../../contexts/telestration-context";

export const SessionPageRouter: FC = () => {
  const socket = UseSocketIoContext();
  const dashboardUrl = useDashboardUrl();

  const [isTestPassed, setIsTestPassed] = useState(false);
  const testPassed = () => {
    setIsTestPassed(true);
  };

  // errorString is either: undefined = not known yet, "" = successful, else = error
  const [errorString, setErrorString] = useState<string | undefined>(undefined);

  useEffect(() => {
    socket.on("error", (error: Error) => {
      if (error.message === ExceptionCodes.PROFILE_NOT_COMPLETED) {
        navigateToDashboard(dashboardUrl);
      } else {
        setErrorString(error.message);
      }
    });

    socket.on("connected", () => {
      console.log("Websocket is connected");
      setErrorString("");
    });
  }, []);

  return (
    <SessionProvider socketIoClientWrapper={UseSocketIoContext()}>
      {(() => {
        switch (errorString) {
          case undefined:
            return <SessionLoading>Connecting</SessionLoading>;
          case "":
            return isTestPassed ? (
              <Routes>
                <Route
                  path="start"
                  element={
                    <ServerAdapterProvider>
                      <GlobalMonitorProvider>
                        <Page
                          path="start"
                          component={SessionStart}
                          title="Session is starting..."
                        />
                      </GlobalMonitorProvider>
                    </ServerAdapterProvider>
                  }
                />

                <Route
                  path="in-progress"
                  element={
                    <ServerAdapterProvider>
                      <GlobalMonitorProvider>
                        <TelestrationProvider>
                          <CameraLabelProvider>
                            <Page
                              path="in-progress"
                              component={SessionInProgress}
                              title="Live session"
                            />
                          </CameraLabelProvider>
                        </TelestrationProvider>
                      </GlobalMonitorProvider>
                    </ServerAdapterProvider>
                  }
                />

                <Route
                  path="end"
                  element={
                    <Page
                      path="end"
                      component={SessionEnd}
                      title="Session has ended"
                    />
                  }
                />
              </Routes>
            ) : (
              <TroubleShooter onTestPassed={testPassed} />
            );
          default:
            return <CatchAllErrorPage errorString={errorString} />;
        }
      })()}
    </SessionProvider>
  );
};

const PageRouter: FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { deepLinkPathAndQuery } = useAuthenticatedUser();

  const getSessionParamsOrNullRedirect = () => {
    // This is for the multi-participants.spec e2e.
    if (
      environment.name === ENV_NAMES.DEFAULT &&
      deepLinkPathAndQuery === "/e2e"
    ) {
      return <Navigate to={`e2e`} />;
    }

    // Correct URL is root + search params; anything else is error.
    if (window.location.pathname !== "/") {
      return <Navigate to="/error/url" />;
    }

    // When logging in directly to media client, deeplink should exist
    // and will be parsed as valid session params URL.
    const deepLinkQuery =
      deepLinkPathAndQuery?.length > 0
        ? deepLinkPathAndQuery.slice(1, deepLinkPathAndQuery.length)
        : "";
    if (isValidSessionParams(deepLinkQuery)) {
      return <Navigate to={`/session/start${deepLinkQuery}`} />;
    }

    // When already logged in (for example, clicking 'Join session' from dashboard),
    // search params in URL are parsed for the valid session params URL.
    if (isValidSessionParams(window.location.search)) {
      return <Navigate to={`/session/start${window.location.search}`} />;
    }

    // Invalid search/session params combinations.
    return <Navigate to="/error/url" />;
  };

  return (
    <Routes>
      <Route
        path="/error/404"
        element={
          <Page path="/error/404" component={Error404} title="Error 404" />
        }
      />
      <Route
        path="/error/session"
        element={
          <Page
            path="/error/session"
            component={ErrorSession}
            title="Error Session"
          />
        }
      />
      <Route
        path="/error/url"
        element={
          <Page path="/error/url" component={ErrorURL} title="Error URL" />
        }
      />

      <Route
        path="/session/*"
        element={<Page component={SessionPageRouter} title="Session" />}
      />
      <Route path="/*" element={getSessionParamsOrNullRedirect()}></Route>
      {/* <Route path="*" element={<Navigate to="/error/404" />} /> */}
    </Routes>
  );
};

export default PageRouter;
