/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useEffect, useRef, useState, useCallback } from "react";
import { DialogTitle, PendoInit, SnackbarContext } from "@proximie/components";
import {
  Connection,
  Device,
  DeviceType,
  ElipMask,
  FeedType,
  MediaUtil,
  RectMask,
  VideoMaskHandler,
  WebRTCUtil,
} from "@proximie/media";
import {
  LiveSessionControlBar,
  SessionLoading,
  SideBar,
} from "../../components";
import SessionInProgressStyled, {
  SessionInProgressTelestrationControlsStyled,
} from "./SessionInProgress.style";
import { UseServerAdapterContext } from "../../contexts/server-adapter-context";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { useNavigate } from "react-router-dom";
import { navigateToDashboard } from "../../utils";
import { TelestrationControls } from "../../components/TelestrationControls/TelestrationControls";
import Video from "../../components/Video/Video";
import AddVideo from "../../components/AddVideo/AddVideo";
import { environment } from "../../../environments/environment";
import { Box, Dialog, DialogContent, Modal } from "@mui/material";
import { ActiveTab } from "../../components/constants";
import BrowserPermissionsDialog, {
  PERMISSION_ERROR_CODES,
} from "../../components/BrowserPermissionsDialog/BrowserPermissionsDialog";
import VideoStore, { VideoInfo } from "../../utils/VideoStore";

import { INTERNAL_USER_EMAIL_SEARCH_STRING } from "../../constants";
import { liveApiSockets } from "@proximie/dataregion-api";
import {
  ExtendedVideoInfo,
  VideoLastStatePanel,
} from "../../components/VideoLastStatePanel/VideoLastStatePanel";
import { useUserSettings } from "../../hooks/useUserSettings";
import VideoMaskingEditor from "../../components/VideoMaskingEditor/VideoMaskingEditor";
import { Permission } from "../../utils/checkPermission";
import { RecordingContextProvider } from "../../contexts/recording-context/recording-context";
import { SpotlightedFeed } from "../../components/SpotlightGrid/SpotlightGrid";
import { ViewMode } from "../../components/ViewMode/ViewMode";
import { SessionFeedView } from "../../models/SessionContext";
import { useTranslation } from "react-i18next";
import { useDashboardUrl } from "../../hooks/useDashboardUrl";
import { exceptions } from "@proximie/common";

const SessionInProgress: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.NONE);
  const [isEndSessionButtonDisabled, setIsEndSessionButtonDisabled] =
    useState(false);
  const isFinishingRef = useRef<boolean>(false);
  const videoFeedBoundaryRef = useRef<HTMLElement | null>(null);

  const dashboardUrl = useDashboardUrl();

  const context = UseServerAdapterContext();
  const {
    endSession,
    leaveSession,
    serverParams,
    sessionParams,
    user,
    chats,
    submitChat,
    socket,
    session,
    organisation,
    members,
    inviteParticipant,
    ShowForPermission,
    checkMyPermission,
    setSessionVideos,
    view,
    spotlight: { spotlightedFeed },
  } = useSessionContext();
  const { showSnackbar, closeSnackbar } = SnackbarContext.useSnackbarContext();

  const profileId = user?.profileId || 0;

  useEffect(() => {
    window.addEventListener("endSession", async () => {
      console.info("UX Event: Ended session");
      isFinishingRef.current = true;
      await context?.destroy();
      if (leaveSession) {
        console.info("UX Event: Leave session");
        leaveSession();
      }
      closeSnackbar();
      navigate("/session/end", { replace: true });
    });

    return () => {
      if (leaveSession) {
        console.info("UX Event: Leave session");
        leaveSession();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const endSessionForAll = async () => {
    if (endSession) {
      setIsEndSessionButtonDisabled(true);
      try {
        console.info("UX Event: Ended session");
        await endSession();
        isFinishingRef.current = true;
        await context?.destroy();
        if (leaveSession) {
          console.info("UX Event: Leave session");
          leaveSession();
        }
        closeSnackbar();
        navigate("/session/end", { replace: true });
      } catch (err) {
        setIsEndSessionButtonDisabled(false);
      }
    }
  };

  const participantLeaveSession = async () => {
    isFinishingRef.current = true;
    await context?.destroy();
    if (leaveSession) {
      console.info("UX Event: Leave session");
      leaveSession();
    }
    navigateToDashboard(dashboardUrl);
  };

  useEffect(() => {
    const connectedFunc = () => {
      closeSnackbar();
    };
    const disconnectedFunc = () => {
      showSnackbar({
        message: {
          body: t("common.components.snackbar.messages.unstableConnection"),
        },
        severity: "warning",
      });
    };

    socket?.on("connected", connectedFunc);
    socket?.on("disconnected", disconnectedFunc);

    return () => {
      socket?.off("connected", connectedFunc);
      socket?.off("disconnected", disconnectedFunc);
    };
  }, [socket, showSnackbar, closeSnackbar]);

  const videoStore = useRef<VideoStore | null>(null);
  useEffect(() => {
    if (sessionParams && user && user.profileId) {
      videoStore.current = new VideoStore(
        sessionParams.mediaSessionId,
        user.id,
        user.profileId,
      );
    }
  }, [sessionParams, user]);

  const [maximisedStreamId, setMaximisedStreamId] = useState<string | null>(
    null,
  );

  const onMaximiseUpdateClick = async (streamId: string | null) => {
    // streamId of null indicates that no feed is maximised
    setMaximisedStreamId(streamId);
  };

  useEffect(() => {
    if (
      context &&
      !context.videos.find((video) => video.streamId === maximisedStreamId)
    ) {
      setMaximisedStreamId(null);
    }
  }, [context, maximisedStreamId]);

  useEffect(() => {
    if (context?.videos) {
      setSessionVideos(context.videos);
    }

    return () => {
      setSessionVideos([]);
    };
  }, [context?.videos, setSessionVideos]);

  type AfterDefaultActionCallbacks = {
    [key in string]: () => void;
  };

  const getMaskHandlerFromConnection = (
    connection: Connection,
  ): VideoMaskHandler | undefined => {
    return (
      connection.boundData as {
        maskHandler: VideoMaskHandler;
      }
    )?.maskHandler;
  };

  const setupConnectionListeners = (
    connection: Connection,
    callbacks: AfterDefaultActionCallbacks,
  ) => {
    const closedHandler = () => {
      const maskHandler = getMaskHandlerFromConnection(connection);
      maskHandler?.stopTracks();
      maskHandler?.destroy();
      connection.off("error", errorHandler);
      callbacks.closed?.();
    };

    connection.once("closed", closedHandler);

    const errorHandler = (error: Error) => {
      console.warn(
        { streamId: connection.streamId },
        "Stream got error=",
        error,
      );
      const maskHandler = getMaskHandlerFromConnection(connection);
      maskHandler?.stopTracks();
      maskHandler?.destroy();
      connection.off("closed", closedHandler);
      callbacks.error?.();
    };

    connection.once("error", errorHandler);
  };

  const initiateScreenShareConnection = async (): Promise<void> => {
    if (!context || !tempMaskHandler.current) {
      return;
    }

    try {
      const connection = await context.sendMedia(
        tempMaskHandler.current.getDevice(),
        {
          boundData: { maskHandler: tempMaskHandler.current },
        },
      );

      console.info(
        { streamId: connection.streamId },
        `UX Event: Start screen ${connection.streamId}`,
      );

      setupConnectionListeners(connection, {
        closed: () => {
          console.info(
            { streamId: connection.streamId },
            `UX Event: Stop screen ${connection.streamId}`,
          );
        },
        error: () => {
          console.info(
            { streamId: connection.streamId },
            `UX Event: Error screen ${connection.streamId}`,
          );
        },
      });
    } catch (error) {
      console.warn("Error re-establishing screenshare feed");
    }
  };

  const initiateConnection = async (
    device: Device,
    streamId?: string,
    stream?: MediaStream,
    maskHandler?: VideoMaskHandler,
  ): Promise<void> => {
    const mediaSessionId = sessionParams?.mediaSessionId;
    if (!mediaSessionId || !context) {
      return;
    }

    let connection: Connection;
    try {
      connection = await context.sendMedia(device, {
        stream,
        streamId,
        boundData: { maskHandler },
      });
    } catch (error) {
      console.warn(
        { streamId },
        `Error establishing - type=${device.deviceType}`,
        error,
      );

      // if sendMedia() throws then we will normally restart the stream (it is very
      // likely that it is a reason that will not correct itself).    However, if the following
      // conditions are true then we will not attempt to restart the stream when the timer
      // expires:
      // 1) We have a corresponding streamId, and
      // 1a) We have more than the allowed number of feeds,
      // 2) It's a DCP device (they will be restarted in different ways!), and
      // 3) The session is not ending, and
      // 4) The endpoint is ready, and
      // 5) The server is not down
      if (
        streamId &&
        (error instanceof exceptions.VideoCapacityExceededException ||
          (device.deviceType !== DeviceType.Dcp &&
            !isFinishingRef.current &&
            !(error instanceof exceptions.EndpointNotInitializedException) &&
            !error.message.startsWith("Is the server down?")))
      ) {
        console.warn(
          { streamId },
          "Removing feed from video store (initialise)",
        );
        videoStore.current?.remove(streamId);
      }
      return;
    }

    videoStore.current?.add({
      streamId: connection.streamId,
      order: connection.order,
      type: connection.type,
      deviceId: device.deviceId,
      uniqueId: device.uniqueId,
      label: device.label,
      masks: maskHandler?.getMasks() ?? [],
    });

    console.info(
      { streamId: connection.streamId },
      `UX Event: Start video ${device.label}`,
    );

    // we need to ensure that streams that failed (ie. got an error event rather
    // than a closed event) are not removed from the store
    setupConnectionListeners(connection, {
      closed: () => {
        console.info(
          { streamId: connection.streamId },
          `UX Event: Stop video ${device.label}`,
        );
        if (!isFinishingRef.current) {
          console.warn(
            { streamId: connection.streamId },
            "Removing feed from video store (in-progress)",
          );
          videoStore.current?.remove(connection.streamId);
        }
      },
      error: () => {
        console.info(
          { streamId: connection.streamId },
          `UX Event: Error video ${device.label}`,
        );
        //TODO - do we need the timeout?
        setTimeout(() => {
          const info = videoStore.current?.videos.find(
            (info: VideoInfo): boolean => info.streamId === connection.streamId,
          );
          if (info) {
            restartVideoFromDevice(device, info);
          }
        }, 1000);
      },
    });
  };

  /////////////////////////--/////////////////////////-- PII FUNCTION - START
  const tempMaskHandler = useRef<VideoMaskHandler | null>(null);
  const [isShowMaskEditor, setIsShowMaskEditor] = useState(false);

  //on choosing new camera to send
  const sendCamera = async (device: Device) => {
    //destroy old mask handler if temp
    if (tempMaskHandler.current) {
      tempMaskHandler.current.destroy();
      tempMaskHandler.current = null;
    }

    if (device.deviceType === DeviceType.Dcp) {
      tempMaskHandler.current = new VideoMaskHandler({
        stream: null,
        device,
        masks: [],
      });

      confirmMaskSendFeed(device);
    } else {
      //create video mask handler
      const stream = await WebRTCUtil.GetVideoMedia(device.deviceId);
      tempMaskHandler.current = new VideoMaskHandler({
        stream,
        device,
        masks: [],
      });

      //show masking sidepanel UI
      setIsShowMaskEditor(true);
    }
  };

  const sendScreen = async () => {
    //destroy old mask handler if temp
    if (tempMaskHandler.current) {
      tempMaskHandler.current.destroy();
      tempMaskHandler.current = null;
    }

    const device = await WebRTCUtil.GetDisplayMedia();
    if (device === "cancelled") {
      return;
    } else if (device === "system_denied") {
      addError(PERMISSION_ERROR_CODES.SHARE_SCREEN);
      return;
    }

    tempMaskHandler.current = new VideoMaskHandler({
      stream: device.options.stream,
      device,
      masks: [],
    });

    //show masking sidepanel UI
    // setIsShowMaskEditor(true);
    confirmMaskSendFeed(device);
  };

  //on open/close privacy panel
  useEffect(() => {
    const handlePrivacyModalClosed = () => {
      if (lastStateVideos.length > 0) return;

      if (!isShowMaskEditor && !!tempMaskHandler.current) {
        tempMaskHandler.current.destroy();
        tempMaskHandler.current = null;
        setIsShowMaskEditor(false);
      }
    };
    handlePrivacyModalClosed();
  }, [activeTab]);

  //cancel masking from side panel
  const cancelMaskSendFeed = () => {
    //close sidepanel
    setActiveTab(ActiveTab.NONE);
    setIsShowMaskEditor(false);

    //destroy temp handler
    if (tempMaskHandler.current) {
      if (
        lastStateVideos.length === 0 &&
        !context?.videos.some(
          (v) => v.streamId === tempMaskHandler.current?.getStreamId(),
        )
      ) {
        tempMaskHandler.current.stopTracks();
      }
      tempMaskHandler.current.destroy();
      tempMaskHandler.current = null;
    }
  };

  const sendMaskEventToPendo = (): void => {
    console.debug("Sending masked_stream_started event to pendo");
    const pendo = (window as any).pendo;
    pendo.track("masked_stream_started", {});
  };

  //confirm send masked video from side panel
  const confirmMaskSendFeed = (device: Device) => {
    //close sidepanel
    setActiveTab(ActiveTab.NONE);
    setIsShowMaskEditor(false);

    if (!tempMaskHandler.current) {
      return;
    }

    //if in last state UI
    if (lastStateVideos.length > 0) {
      return;
    }

    if (tempMaskHandler.current.getIsEdit()) {
      const streamId = tempMaskHandler.current.getStreamId();
      const connection = context?.videos.find((v) => v.streamId === streamId);

      if (tempMaskHandler.current.getIsRemoteEdit()) {
        const masks = tempMaskHandler.current.getMasks();
        //i am session owner editing remote masks
        //tell sender about changes
        console.info(
          { streamId },
          "UX Event: Add/change/delete masks as a non-owner",
          { masks },
        );
        socket?.broadcastSync(
          liveApiSockets.MediaSessionEventBroadcastTopics.privacyMask,
          {
            action: "confirmChange",
            streamId,
            masks: masks,
          },
        );

        tempMaskHandler.current.destroy();
        tempMaskHandler.current = null;
        return;
      }
      const currentMasker = connection
        ? getMaskHandlerFromConnection(connection)
        : undefined;

      const masks = tempMaskHandler.current.getMasks();
      tempMaskHandler.current.destroy();
      tempMaskHandler.current = null;

      if (!currentMasker) throw new Error(`stream ${streamId} doesnt exist`);

      if (!currentMasker.hasEverBeenMasked) {
        sendMaskEventToPendo();
      }

      console.info(
        { streamId },
        `UX Event: Add/change/delete masks for device: ${device.label}`,
        {
          masks,
        },
      );
      currentMasker.setMasks(masks);
      const mediaSessionId = sessionParams?.mediaSessionId;
      if (!mediaSessionId) return;
      videoStore.current?.setMasks(streamId, masks);

      return;
    }

    if (device.mediaType === FeedType.Screen) {
      initiateScreenShareConnection();
    } else {
      setIntendedCamerasSent((old) => [...old, device]);
      initiateConnection(
        device,
        undefined,
        tempMaskHandler.current.getStream() as MediaStream,
        tempMaskHandler.current,
      );
    }

    if (tempMaskHandler.current?.hasEverBeenMasked) {
      console.info(`UX Event: Add masks for device: ${device.label}`, {
        masks: tempMaskHandler.current.getMasks(),
      });
      sendMaskEventToPendo();
    }

    //remove temp handler
    tempMaskHandler.current = null;
  };

  const [videoWithMask, setVideoWithMask] = useState(false);

  const checkIfVideoWithMasks = useCallback(
    (streamId: string): void => {
      const video = context?.videos.find((v) => v.streamId === streamId);
      if (!video || !user) return;
      if (video.userId === user.id) {
        // im the sender
        const testMasker = getMaskHandlerFromConnection(video);
        if (testMasker && testMasker.getMasks().length > 0) {
          setVideoWithMask(true);
        } else {
          setVideoWithMask(false);
        }
      } else {
        //ask for data
        socket?.broadcastSync(
          liveApiSockets.MediaSessionEventBroadcastTopics.privacyMask,
          { action: "requestData_test", streamId },
        );
      }
    },
    [context?.videos, profileId, socket],
  );

  //start editing a privacy mask
  const editPrivacyMask = (streamId: string): void => {
    const video = context?.videos.find((v) => v.streamId === streamId);
    if (!video) throw new Error("video doesnt exist");

    //destroy old mask handler if temp
    if (tempMaskHandler.current) {
      tempMaskHandler.current.destroy();
      tempMaskHandler.current = null;
    }

    //if im the sender
    if (video.userId === user?.id) {
      const currentMasker = getMaskHandlerFromConnection(video);
      if (currentMasker) {
        //create video mask handler
        tempMaskHandler.current = new VideoMaskHandler({
          stream: currentMasker.getInputStream(),
          device: currentMasker.getDevice(),
          masks: currentMasker.getMasks(),
          isEdit: true,
          streamId: streamId,
        });
      }
    }
    // else if im the owner
    else if (
      checkMyPermission([
        Permission.EDIT_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED,
        Permission.ADD_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED,
      ])
    ) {
      //ask for data
      socket?.broadcastSync(
        liveApiSockets.MediaSessionEventBroadcastTopics.privacyMask,
        { action: "requestData", streamId },
      );

      //create video mask handler
      tempMaskHandler.current = new VideoMaskHandler({
        stream: video.stream as MediaStream,
        device: {} as Device,
        masks: [],
        isEdit: true,
        streamId: streamId,
        isRemoteEdit: true,
      });
    }

    //show masking sidepanel UI
    setIsShowMaskEditor(true);
  };

  const videos = useRef(context?.videos);
  useEffect(() => {
    videos.current = context?.videos;
  }, [context]);

  //handle privacy tool socket events
  useEffect(() => {
    if (!socket) return;

    const handleSocketMessags = (data: {
      action: string;
      streamId: string;
      masks: (RectMask | ElipMask)[];
    }) => {
      switch (data.action) {
        //if someone requested data, answer him
        case "requestData":
          {
            const video = videos.current?.find(
              (v) => v.streamId === data.streamId,
            );
            if (!video || !user) return;
            if (video.userId !== user.id) return;
            //if im the sender, reply with data
            const currentMasker = getMaskHandlerFromConnection(video);
            if (currentMasker) {
              socket?.broadcastSync(
                liveApiSockets.MediaSessionEventBroadcastTopics.privacyMask,
                {
                  action: "receiveData",
                  streamId: data.streamId,
                  masks: currentMasker.getMasks(),
                },
              );
            }
          }
          break;
        case "requestData_test":
          {
            const video = videos.current?.find(
              (v) => v.streamId === data.streamId,
            );
            if (!video || !user) return;
            if (video.userId !== user.id) return;
            //if im the sender, reply with data
            const currentMasker = getMaskHandlerFromConnection(video);
            if (currentMasker) {
              socket?.broadcastSync(
                liveApiSockets.MediaSessionEventBroadcastTopics.privacyMask,
                {
                  action: "receiveData_test",
                  streamId: data.streamId,
                  masks: currentMasker.getMasks(),
                },
              );
            }
          }
          break;
        //if someone gave me requested data, apply it
        case "receiveData":
          if (
            !tempMaskHandler.current ||
            tempMaskHandler.current?.getStreamId() !== data.streamId
          )
            return;
          tempMaskHandler.current?.setMasks(data.masks, true);
          tempMaskHandler.current?.setOriginMasks(data.masks);
          break;
        case "receiveData_test":
          if (data.masks.length > 0) {
            setVideoWithMask(true);
          } else {
            setVideoWithMask(false);
          }
          break;
        //if someone changed my mask, apply it
        case "confirmChange":
          {
            const video = videos.current?.find(
              (v) => v.streamId === data.streamId,
            );
            if (!video || !user) return;
            if (video.userId !== user.id) return;
            //if im the sender, reply with data
            const currentMasker = getMaskHandlerFromConnection(video);
            if (!currentMasker) return;
            console.info(
              { streamId: data.streamId },
              `Your mask has been changed by the Session Owner or Moderator for the device: ${
                currentMasker.getDevice().label
              }`,
              { masks: data.masks },
            );
            currentMasker.setMasks(data.masks);
          }
          break;
      }
    };

    socket.onBroadcast(
      liveApiSockets.MediaSessionEventBroadcastTopics.privacyMask,
      handleSocketMessags,
    );

    return () => {
      socket.offBroadcast(
        liveApiSockets.MediaSessionEventBroadcastTopics.privacyMask,
        handleSocketMessags,
      );
    };
  }, [user?.profileId, socket]);

  const VideoMaskingPanelProps = {
    confirmMaskSendFeed,
    cancelMaskSendFeed,
    tempMaskHandler: tempMaskHandler.current,
  };
  /////////////////////////--/////////////////////////-- PII FUNCTION - END

  const [intendedCamerasSent, setIntendedCamerasSent] = useState<Device[]>([]);
  const removeCameraIntent = (deviceId: string) => {
    setIntendedCamerasSent((prev: Device[]): Device[] =>
      missingDevices.reduce(
        (result: Device[], current: MediaDeviceInfo): Device[] => {
          if (current.deviceId !== deviceId) {
            const found = prev.find(
              (myDevice: Device): boolean =>
                myDevice.deviceId === current.deviceId,
            );
            if (found) {
              result.push(found);
            }
          }
          return result;
        },
        [],
      ),
    );
  };

  useEffect(() => {
    if (context?.isActive && context?.cameraMonitor) {
      console.log("Starting videos?");
      context.cameraMonitor.on("added", restartVideo);
      context.cameraMonitor.deviceList.forEach(restartVideo);
    }

    return () => {
      context?.cameraMonitor?.off("added", restartVideo);
    };
  }, [context?.isActive, context?.cameraMonitor]);

  const handleVideoRestart = async (video: VideoInfo) => {
    if (context?.hasVideo(video.streamId)) {
      console.debug(
        { streamId: video.streamId },
        "Already receiving stream - ignoring",
      );
      return;
    }

    const devices = context?.cameraMonitor?.deviceList || [];

    const device = devices.find(
      (myDevice: Device): boolean => myDevice.uniqueId === video.uniqueId,
    );
    if (!device) {
      console.warn(
        { streamId: video.streamId },
        "Device no longer exists - deviceId=",
        video.deviceId,
      );
      return;
    }

    return restartVideoFromDevice(device, video, true);
  };

  const restartVideoFromDevice = async (
    device: Device,
    video: VideoInfo,
    isNew?: boolean,
  ): Promise<void> => {
    console.debug("restartVideoFromDevice", device.state);
    if (!device.services || !device.state) {
      console.debug({ deviceId: device.deviceId }, "Device is not available");
      return;
    }

    if (
      !context?.cameraMonitor?.deviceList.find(
        (myDevice: Device): boolean => myDevice.deviceId === device.deviceId,
      )
    ) {
      // if a USB camera has disconnected we might be delayed in receving the
      // notification from the monitor.
      console.debug({ deviceId: device.deviceId }, "Device is not present");
      return;
    }

    const stream =
      device.deviceType === DeviceType.Dcp
        ? undefined
        : await WebRTCUtil.GetVideoMedia(device.deviceId);

    const streamId = isNew ? undefined : video.streamId;

    console.debug(
      { deviceId: device.deviceId },
      "Re-establishing video stream - streamId=",
      streamId,
    );

    setIntendedCamerasSent((old: Device[]) => [...old, device]);
    //create video mask handler
    const maskHandler = new VideoMaskHandler({
      stream,
      device,
      masks: video.masks,
    });
    return initiateConnection(
      device,
      streamId,
      maskHandler.getStream() as MediaStream,
      maskHandler,
    );
  };

  const restartVideo = (device: Device) => {
    console.log("restartVideo device=", device);
    videoStore.current?.videos.forEach((item: VideoInfo): void => {
      if (
        !context?.hasVideo(item.streamId) &&
        item.uniqueId === device.uniqueId
      ) {
        restartVideoFromDevice(device, item).catch(() => {
          /* ignore */
        });
      }
    });
  };

  const [missingDevices, setMissingDevices] = useState<MediaDeviceInfo[]>([]);
  const addMissingDevices = (devices: MediaDeviceInfo[]) => {
    const missing = devices.filter(
      (device) => !missingDevices.includes(device),
    );
    missing
      .filter(({ kind }) => kind === "videoinput")
      .forEach(({ deviceId }) => removeCameraIntent(deviceId));

    setMissingDevices(missing);

    const snackbarMessage = (devices: MediaDeviceInfo[]) => {
      const isMic = devices.every((item) => item.kind === "audioinput");
      const isCam = devices.every((item) => item.kind === "videoinput");
      const deviceList = devices.map((device) => device.label).join("   |   ");

      if (isMic) {
        return {
          title: t("common.components.snackbar.messages.micDisconnectedTitle", {
            count: devices.length,
            deviceList,
          }),
          body: t("common.components.snackbar.messages.micDisconnected", {
            count: devices.length,
            deviceList,
          }),
        };
      }

      if (isCam) {
        return {
          title: t(
            "common.components.snackbar.messages.cameraDisconnectedTitle",
            { count: devices.length, deviceList },
          ),
          body: t("common.components.snackbar.messages.cameraDisconnected", {
            count: devices.length,
            deviceList,
          }),
        };
      }

      return {
        title: t(
          "common.components.snackbar.messages.genericDevicesDisconnectedTitle",
          { count: devices.length, deviceList },
        ),
        body: t(
          "common.components.snackbar.messages.genericDevicesDisconnected",
          { count: devices.length, deviceList },
        ),
      };
    };

    missing.length > 0 &&
      showSnackbar({
        message: snackbarMessage(missing),
        severity: "warning",
      });
  };

  const handleLSPDevicesChange = async (connectedDevices: Device[]) => {
    if (lastStateVideos.length === 0) return;

    const newVideosArray = [...lastStateVideos];
    //check last state devices
    newVideosArray.forEach((lsd) => {
      //find connected device
      const cd = connectedDevices.find(
        (d: Device): boolean => d.uniqueId === lsd.uniqueId,
      );

      //if last state OR new available device not found
      if (!cd) {
        lsd.isMissing = true;
        lsd.isSelected = false;
        lsd.masker?.destroy();
        lsd.masker = undefined;
        return;
      }

      //if last state device is found but was disconencted/missing
      if (lsd.isMissing) {
        lsd.isMissing = false;
      }
    });

    //if new available device appeared
    connectedDevices.forEach((device: Device): void => {
      if (
        !newVideosArray.find(
          (item: ExtendedVideoInfo): boolean =>
            item.uniqueId === device.uniqueId,
        )
      ) {
        newVideosArray.push({
          streamId: device.deviceId,
          order: "",
          type: FeedType.Camera,
          deviceId: device.deviceId,
          //TODO - tidy this up when we add DCP cameras to LSP
          uniqueId: `${DeviceType.Camera}:${device.deviceId}`,
          label: device.label,
          masks: [],
          isNew: true,
        });
      }
    });

    //update LSP state
    setLastStateVideos([...newVideosArray]);

    //lazy start videos for new available devices
    newVideosArray.forEach(async (item) => {
      if (!item.masker) {
        const stream = await WebRTCUtil.GetVideoMedia(item.deviceId);
        item.masker = new VideoMaskHandler({
          stream,
          masks: item.masks || [],
          device: { deviceId: item.deviceId } as Device,
          streamId: item.deviceId,
        });
        setLastStateVideos([...newVideosArray]);
      }
    });
  };

  const [isCheckingDeviceChange, setIsCheckingDeviceChange] = useState(false);
  //TODO: we should listen to the monitor here....
  navigator.mediaDevices.ondevicechange = async () => {
    if (isCheckingDeviceChange) return;
    setIsCheckingDeviceChange(true);

    //get all connected devices
    const connectedDevices = context?.cameraMonitor?.deviceList || [];

    //check for privacy editor
    if (
      tempMaskHandler.current &&
      tempMaskHandler.current?.getDevice().mediaType !== FeedType.Screen
    ) {
      const cd = connectedDevices.find(
        (d: Device): boolean =>
          d.deviceId ===
          (tempMaskHandler.current?.getDevice() as Device).deviceId,
      );
      //if divice was disconnected
      if (!cd) {
        cancelMaskSendFeed();
      }
    }

    //check for active feeds
    const missing = await WebRTCUtil.CheckMissingDevices(
      context?.mic?.options.deviceInfo,
      context?.speaker?.options.deviceInfo,
      intendedCamerasSent,
    );

    addMissingDevices(missing);

    //check LSP videos if session owner
    if (session?.owner.profileId === user?.profileId) {
      await handleLSPDevicesChange(connectedDevices);
    }

    setIsCheckingDeviceChange(false);
  };

  const [selectedPtzDevice, setSelectedPtzDevice] = useState<
    string | undefined
  >();

  const [errorsArray, setErrorsArray] = useState<PERMISSION_ERROR_CODES[]>([]);
  const dismissError = (err: PERMISSION_ERROR_CODES) => {
    setErrorsArray(errorsArray.filter((erra) => erra !== err));
  };
  const addError = (err: PERMISSION_ERROR_CODES) => {
    !errorsArray.includes(err) && setErrorsArray((old) => [...old, err]);
  };

  const checkMic = async () => {
    const result = await WebRTCUtil.CheckAndRequestDevicePermission(
      "microphone" as PermissionName,
    );
    if (result === "system_denied" || result === "browser_denied") {
      addError(PERMISSION_ERROR_CODES.BLOCKED_MIC);
    }
  };

  const checkCam = async () => {
    const result = await WebRTCUtil.CheckAndRequestDevicePermission(
      "camera" as PermissionName,
    );
    if (result === "system_denied" || result === "browser_denied") {
      addError(PERMISSION_ERROR_CODES.BLOCKED_CAM);
    }
  };

  const checkDevices = async () => {
    const connectedDevices = await WebRTCUtil.GetDeviceList();

    if (connectedDevices.videoinput.length <= 0) {
      showSnackbar({
        message: {
          title: t(
            "common.components.snackbar.messages.noConnectedDevicesFoundTitle",
          ),
          body: t(
            "common.components.snackbar.messages.noConnectedDevicesFound",
          ),
        },
        severity: "info",
      });
    }
  };

  useEffect(() => {
    checkMic();
    checkCam();
    checkDevices();
  }, []);

  // emulates the dashboard setting (Last State Preview on/off)
  const { settings, isUserSettingsLoading } = useUserSettings({
    suspense: false,
  });
  const [lastStateVideos, setLastStateVideos] = useState<ExtendedVideoInfo[]>(
    [],
  );

  useEffect(() => {
    const createLastStateMaskers = async (videosArray: ExtendedVideoInfo[]) => {
      for (const video of videosArray) {
        const stream = await WebRTCUtil.GetVideoMedia(video.deviceId);
        const masker = new VideoMaskHandler({
          stream,
          masks: video.masks,
          device: { deviceId: video.deviceId } as Device,
          streamId: video.streamId,
        });
        video.masker = masker;
        setLastStateVideos([...videosArray]);
      }
    };

    const prepareLastStateFeeds = async (videos: VideoInfo[]) => {
      //LATER: When we do LSP with NDI devices then we'll have devices
      // turning up at different times.   For now, we just filter out the
      // NDI devices
      const devices = context?.cameraMonitor?.deviceList || [];
      videos = videos.filter((video: VideoInfo): boolean => {
        const device = devices.find(
          (device: Device): boolean => device.uniqueId === video.uniqueId,
        );
        return !!device && device.deviceType !== DeviceType.Dcp;
      });

      await checkCam();
      if (
        settings.liveSession.lastStatePreviewBehavior === "AUTOMATICALLY_ADD" &&
        checkMyPermission(Permission.INSTANT_VIDEO)
      ) {
        videos.forEach((video) => {
          handleVideoRestart(video);
        });
        return;
      }

      //add last state devices/streams
      const videosArray: ExtendedVideoInfo[] = [];

      videos.forEach((item: VideoInfo): void => {
        const foundDevice = devices.find(
          (device) => device.uniqueId === item.uniqueId,
        );
        if (!foundDevice) {
          videosArray.push({
            ...item,
            isMissing: true,
          });
          return;
        }

        videosArray.push({
          ...item,
          isSelected: true,
        });
      });

      //add remaining devices
      devices.forEach((device) => {
        const isPresent = !!videosArray.find(
          (v: ExtendedVideoInfo): boolean => v.uniqueId === device.uniqueId,
        );
        if (!isPresent) {
          videosArray.push({
            streamId: device.deviceId,
            order: "",
            type: FeedType.Camera,
            deviceId: device.deviceId,
            uniqueId: device.uniqueId,
            label: device.label,
            masks: [],
            isNew: true,
          });
        }
      });

      if (videosArray.length === 0) return;

      setLastStateVideos([...videosArray]);
      createLastStateMaskers(videosArray);
    };

    const setupLSP = () => {
      //don't continue before media state is active
      if (!context?.isActive) {
        return;
      }

      //don't continue before we have user settings
      if (isUserSettingsLoading) {
        return;
      }

      //condition NOT_SESSION_OWNER, return
      if (!checkMyPermission(Permission.LAST_STATE_PREVIEW)) {
        return;
      }

      //DEBUG comment this line to force last state
      //condition NOT_FIRST_JOIN, return
      if (videoStore.current && videoStore.current.videos.length > 0) {
        return;
      }

      //else we have last state and need to apply
      prepareLastStateFeeds(videoStore.current?.lastStateVideos ?? []);
    };
    setupLSP();
  }, [settings, context?.isActive]);

  const handleCloseLastStatePanel = () => {
    lastStateVideos.forEach((item) => {
      if (item.masker) {
        item.masker.stopTracks();
        item.masker.destroy();
      }
    });
    setLastStateVideos([]);
  };

  const removeOldestVideosIfRequired = async (
    newVideos: ExtendedVideoInfo[],
  ): Promise<void> => {
    const MAXIMUM_VIDEOS = 4;

    if (
      !videos.current ||
      videos.current.length + newVideos.length <= MAXIMUM_VIDEOS
    ) {
      return;
    }

    const numberToRemove =
      (videos.current.length + newVideos.length) % MAXIMUM_VIDEOS;

    // sort descending so the videos we want to remove are at the head
    const sortedByTimestamp = [...videos.current].sort(
      (videoA: Connection, videoB: Connection): number => {
        const { timestamp: timestampA } = MediaUtil.decodeStreamId(
          videoA.streamId,
        );
        const { timestamp: timestampB } = MediaUtil.decodeStreamId(
          videoB.streamId,
        );
        return timestampB - timestampA;
      },
    );

    for await (const video of sortedByTimestamp.slice(0, numberToRemove)) {
      console.debug({ streamId: video.streamId }, "Closing");
      await video.kick();
    }
  };

  const handleAddLastStatePanelVideos = async () => {
    const videosToStart = lastStateVideos.filter(
      (item) => item.isSelected && !item.isMissing,
    );

    await removeOldestVideosIfRequired(videosToStart);

    videosToStart.forEach((video) => {
      // info log UX Event of masks being applied
      const masks = video.masker?.getMasks();
      if (masks && masks.length > 0) {
        console.info(
          { streamId: video.streamId },
          `Add masks from LSP for ${video.label}`,
          {
            masks,
          },
        );
      }
      handleVideoRestart({ ...video, masks });
    });

    handleCloseLastStatePanel();

    showSnackbar({
      message: { body: t("common.components.snackbar.messages.allFeedsAdded") },
      severity: "success",
    });
  };

  const removeLastStateVideo = (streamId: string) => {
    const newVideosArray = [...lastStateVideos];

    const video = newVideosArray.find((item) => item.streamId === streamId);
    if (!video) {
      console.warn("something went wrong. last state video not found");
      return;
    }

    video.isSelected = false;
    setLastStateVideos([...newVideosArray]);
  };

  const editPrivacyLastStateVideo = (streamId: string) => {
    const feed = lastStateVideos.find((item) => item.streamId === streamId);

    if (feed?.masker) {
      tempMaskHandler.current = feed.masker;

      //show masking sidepanel UI
      setIsShowMaskEditor(true);
    } else console.warn("cant find masker for ", streamId);
  };

  const undoRemoveLastStateVideo = (streamId: string) => {
    const newVideosArray = [...lastStateVideos];

    const video = newVideosArray.find((item) => item.streamId === streamId);
    if (!video) {
      console.warn("something went wront. last state video not found");
      return;
    }

    video.isSelected = true;
    setLastStateVideos([...newVideosArray]);
  };

  if (!context?.isActive) {
    return <SessionLoading>Joining session</SessionLoading>;
  }

  return (
    <RecordingContextProvider>
      <SessionInProgressStyled data-testid="session-in-progress-container">
        {!!user?.id &&
          user?.profileReference &&
          user?.organisationMemberships &&
          !!organisation?.id && (
            <PendoInit
              profileReference={user.profileReference}
              organisationName={
                user.organisationMemberships?.find(
                  (element) => element.id === organisation.id,
                )?.name || ""
              }
              environmentName={environment.name}
              dnsRecordName={environment.dnsRecordName}
              isInternalUser={user?.email?.includes(
                INTERNAL_USER_EMAIL_SEARCH_STRING,
              )}
            />
          )}

        <Box display={"flex"} flex={1} overflow={"hidden"}>
          <Box
            ref={videoFeedBoundaryRef}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="100%"
            position="relative"
          >
            <Modal
              open={Boolean(tempMaskHandler.current && isShowMaskEditor)}
              onClose={cancelMaskSendFeed}
              componentsProps={{
                root: {
                  id: "something modal root",
                  style: {
                    margin: "32px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    flexGrow: 1,
                  },
                },
              }}
            >
              <VideoMaskingEditor
                maskHandler={tempMaskHandler.current as never}
                {...VideoMaskingPanelProps}
                confirmMaskSendFeed={confirmMaskSendFeed}
                edit={Boolean(tempMaskHandler.current?.getStreamId())}
                enableBackButton={Boolean(lastStateVideos.length !== 0)}
              />
            </Modal>

            {lastStateVideos.length !== 0 && !isShowMaskEditor && (
              <ShowForPermission permission={Permission.LAST_STATE_PREVIEW}>
                <VideoLastStatePanel
                  videos={lastStateVideos}
                  handleCloseLastStatePanel={handleCloseLastStatePanel}
                  handleAddLastStatePanelVideos={handleAddLastStatePanelVideos}
                  removeLastStateVideo={removeLastStateVideo}
                  editPrivacyLastStateVideo={editPrivacyLastStateVideo}
                  undoRemoveLastStateVideo={undoRemoveLastStateVideo}
                />
              </ShowForPermission>
            )}

            {lastStateVideos.length === 0 && context.cameraMonitor && (
              <ViewMode
                emptySlot={
                  <AddVideo
                    videos={context.videos}
                    sendCamera={sendCamera}
                    isCondensed={false}
                    checkCam={checkCam}
                  />
                }
                view={view}
              >
                {socket && user && sessionParams
                  ? context.videos
                      .filter(
                        (video) =>
                          maximisedStreamId === null ||
                          video.streamId === maximisedStreamId,
                      )
                      .map((video, index) => {
                        const feed = (
                          <Video
                            numberOfVideos={context.videos.length}
                            key={video.streamId}
                            streamId={video.streamId}
                            index={index}
                            connection={video}
                            socket={socket}
                            sessionParams={sessionParams}
                            onMaximiseUpdateClick={onMaximiseUpdateClick}
                            isMaximised={!!maximisedStreamId}
                            ptzUser={
                              (video.params.devices &&
                                context.ptzUsers[video.streamId]) ||
                              null
                            }
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            isPtzSelected={
                              activeTab === ActiveTab.PTZPANEL &&
                              video.streamId === selectedPtzDevice
                            }
                            selectPTZ={
                              video.params.devices &&
                              context.ptzUsers[video.streamId] &&
                              video.streamId !== selectedPtzDevice &&
                              activeTab === ActiveTab.PTZPANEL
                                ? setSelectedPtzDevice
                                : undefined
                            }
                            removeCameraIntent={removeCameraIntent}
                            editPrivacyMask={
                              video.params?.capabilities?.canMask
                                ? editPrivacyMask
                                : undefined
                            }
                            videoWithMask={videoWithMask}
                            checkIfVideoWithMasks={checkIfVideoWithMasks}
                            videoFeedBoundaryElement={
                              videoFeedBoundaryRef?.current
                            }
                          />
                        );
                        return view === SessionFeedView.SPOTLIGHT_VIEW &&
                          video.streamId === spotlightedFeed?.streamId ? (
                          <SpotlightedFeed key={video.streamId}>
                            {feed}
                          </SpotlightedFeed>
                        ) : (
                          feed
                        );
                      })
                  : null}
              </ViewMode>
            )}
          </Box>

          {user && chats && submitChat && context && (
            <Box
              sx={
                socket?.isConnected
                  ? {}
                  : { pointerEvents: "none", opacity: "0.4" }
              }
              display="flex"
            >
              <SideBar
                chats={chats}
                submitChat={submitChat}
                onLeave={participantLeaveSession}
                muteAll={context.muteAll}
                setMuteState={context.setMuteState}
                audioParticipants={context.audioParticipants}
                session={session}
                members={members}
                inviteParticipant={inviteParticipant}
                user={user}
                ptzUsers={context.ptzUsers}
                connections={context.videos}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                selectedPtzDevice={selectedPtzDevice}
                setSelectedPtzDevice={setSelectedPtzDevice}
                videoWithMask={videoWithMask}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={
            socket?.isConnected ? {} : { pointerEvents: "none", opacity: "0.4" }
          }
        >
          <LiveSessionControlBar
            addVideo={
              context.cameraMonitor && (
                <AddVideo
                  videos={context.videos}
                  sendCamera={sendCamera}
                  isCondensed
                  checkCam={checkCam}
                />
              )
            }
            startedAt={serverParams?.startedAt}
            mic={{
              isConnected: !!context.mic,
              isMuted: context.participants.local.isMuted,
              isActive: context.participants.local.isActive,
              onClick: () =>
                context.setMuteState(
                  context.participants.local.streamId,
                  !context.participants.local.isMuted,
                ),
            }}
            onScreenShareClick={() => {
              sendScreen();
            }}
            isEndSessionButtonDisabled={isEndSessionButtonDisabled}
            onEndSessionClick={() => endSessionForAll()}
            checkMic={checkMic}
          />
        </Box>

        {lastStateVideos.length === 0 &&
        user &&
        socket &&
        context?.videos?.length ? (
          <SessionInProgressTelestrationControlsStyled data-testid="telestration-controls">
            <TelestrationControls socket={socket} videos={context.videos} />
          </SessionInProgressTelestrationControlsStyled>
        ) : null}

        <Dialog
          open={context.errorDialog.show}
          onClose={() =>
            context.setErrorDialog({ ...context.errorDialog, show: false })
          }
        >
          <DialogTitle
            onClose={() =>
              context.setErrorDialog({ ...context.errorDialog, show: false })
            }
          >
            Error
          </DialogTitle>

          <DialogContent>{context.errorDialog.text}</DialogContent>
        </Dialog>
        {errorsArray.length > 0 && (
          <BrowserPermissionsDialog
            error={errorsArray[0]}
            dismissError={dismissError}
          />
        )}
      </SessionInProgressStyled>
    </RecordingContextProvider>
  );
};

export default SessionInProgress;
